import React from 'react';
import ScoreMain from '../../Components/AdminComp/Components/Score/ScoreMain/ScoreMain';
import SliderPanel from "../../Components/AdminComp/Components/SliderPanel/SliderPanel";

function Score() {

    return(

        <div>
            <SliderPanel />
          <ScoreMain />
        </div>
    );


}
export default Score;
