import React from "react";
import "./style.css";
import logo from '../../assets/FIALOGO.jpg'

function Footer() {

    return (

        <div>

            <div className="d-flex flex-column h-100">





                <footer className="w-100 py-3 flex-shrink-0 text-white">
                    <div className="container py-2">
                        <div className="row gy-4 gx-5">
                            <div className="col-lg-4 col-md-6">
                                {/* <h5 className="h1 text-white">Future IT Academy</h5> */}
                                <a className="navbar-brand" href="/#">
                            <img src={logo} height={50} alt=""/>
                        </a>
                                <p className="small text-muted">Icebreaking Revolution in Computer Education</p>
                                <p className="small text-muted mb-0"> Copyright © 2008-2022 Future IT Academy. All Rights Reserved.</p>
                            </div>
                            <div className="col-lg-2 col-md-6">
                                <h5 className="text-white mb-3">About us</h5>
                                <ul className="list-unstyled text-muted">
                                <li><a href="/">Home</a></li>
                                    <li><a href="/About_FIA">About FIA</a></li>
                                    <li><a href="/Vission_and_Mission">Vission and Mission</a></li>
                                    <li><a href="Director_message">Director's Message</a></li>
                                   
                                </ul>
                            </div>
                            <div className="col-lg-2 col-md-6 text-white">
                                <h5 className="text-white mb-3">Offered</h5>
                                <ul className="list-unstyled text-muted">
                                    <li><a href="/studentregistration">Student Registration</a></li>
                                    <li><a href="/Verification">Certificate Verification</a></li>
                                    <li><a href="/enquiry">Enquiry Form</a></li>
                                    <li><a href="/franchiseeenquiry">Franchisee Enquiry Form</a></li>
                                    
                                    <li><a href="/howtogetaffiliation">How to get affiliation</a></li>
                                    <li><a href="/Courses_Offered">Courses Offered</a></li>
                                </ul>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <h5 className="text-white mb-3">Get in Touch</h5>



                                <div className="form-header text-muted accent-1">
                                    <p className="text-justify"><i className="fas fa-map-marked-alt"></i> Near Fuwara Chowk, Station Road, City - Chandpur, District- Bijnor, State- Uttar Pradesh, India (246725)</p>
                                </div>




                                <div className="row">


                                    <div className="col-md-12 text-muted">

                                        <p><i className="fas fa-phone"></i> +91 7417367384 (Mon - Sat, 09:00-18:00)</p>
                                        <p><i className="fas fa-envelope"></i> seemagoyal12345@gmail.com</p>
                                    </div>




                                </div>


                            </div>
                        </div>
                    </div>
                </footer>
            </div>


        </div >

    );

}

export default Footer;