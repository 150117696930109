import React, { useEffect, useState } from "react";
import './style.css';

import {
  updateScore,
} from "../../../services/score-service";
import { Modal, Button } from "react-bootstrap";
import {getStudentData} from "../../../services/student-service";

function ScoreLeft({ currentRollNo, fetchStudentData, currentCourse=[], courseName }) {
  console.log('current course', currentCourse);

  const [rollNo, setRollNo] = useState(currentRollNo);
  const [showItemExistingModal, setItemExistingModal] = useState(false);
  const [marksData, setMarksData] = useState({});

  const handleMarksData = (e) => {
    setMarksData({
      ...marksData,
      [e.target.name] : e.target.value,
    })
    console.log('marks data', marksData);
  }

  useEffect(() => {
    setRollNo(currentRollNo);

  }, [currentRollNo]);

  const createSubjectsArray = (marksData) => {
    const subjectArray = [];
    Object.entries(marksData).map(([key, value]) => {
      subjectArray.push({
        name: key,
        marks: value
      })
    })
    return subjectArray;
  }


  const handleUpdateScore = async () => {
    try{
    const res = await updateScore(
      currentRollNo, {
        courseName: courseName,
        subjects: createSubjectsArray(marksData),

        }

    );
    }
    catch(err){

      setItemExistingModal(true);
    }
    await getStudentData();
    setRollNo("");
    setMarksData({});

  };


  return (
    <div className="d-flex flex-column justify-content-between height-100">
      <div>
        <div className="Facility-left-position font-weight-bold">
          <p className="mx-1 mb-1 mt-3">Roll No.</p>

          <div class="form-group">
            <input
              type="text"
              class="input-formatting"
              onChange={(e) => setRollNo(e.target.value)}
              value={rollNo}
            />

            {
              currentCourse.map(course => (
                  <>
                    <p className="mx-1 mb-1 mt-3">{course}</p>
                  <input
                      type="text"
                      className="input-formatting"
                      onChange={(e) => handleMarksData(e)}
                      value={marksData.course}
                      name={course}
                      placeholder={course}
                   />
                  </>

              ))
            }


          </div>



          <div className="d-flex flex-row ">


            <>

              <Modal show={showItemExistingModal} onHide={()=>{setItemExistingModal(false)}}>
                <Modal.Header closeButton>
                  <Modal.Title>Already Exist</Modal.Title>
                </Modal.Header>
                <Modal.Body>This record is already exist</Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    onClick={() => {
                      setItemExistingModal(false)
                    }}
                  >
                    OK
                  </Button>
                </Modal.Footer>
              </Modal>

            </>
            <button
              type="button"
              className={`btn btn-green btn-sm px-4 shadow ${
                !(rollNo)  || (currentRollNo=== rollNo) ? "not-allowed" : null
              }`}
              onClick={handleUpdateScore}
            >
              Modify
            </button>
            <button
          type="button"
          className="btn btn-green btn-sm px-3 shadow"
          onClick={() => {
            setRollNo("");

            getStudentData();
          }}
        >
          Deselect
        </button>
          </div>
        </div>
      </div>

    </div>
  );
}

export default ScoreLeft;
