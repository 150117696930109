import styled from 'styled-components';
import lekoIcon from '../../assets/default_icon_leko.svg';


export const ImageContainer = styled.div`
    width: 131px;
    height: 131px;
    background: #C4C4C4;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .image-back{
        position:relative;

        .plus-icon {
            position:absolute;
            right: 5px;
            bottom: 5px;
            width: 23px;
            height: 23px;
        }
    }

    .picked-image {
        width: 131px;
        height:131px;
        border-radius: 5px; 
    }
    .text {
        font-size: 12px;
        color: #fff;
        text-align: center;
        width: 80px;
    }
    .image-picker {
        background:  url(${lekoIcon});
        width: 131px;
        height: 131px;
        border-radius: 5px; 
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .plus-icon {
            width: 23px;
            height: 23px;
            position: absolute;
            right: 5px;
            bottom: 5px;
        }
    }
`;