import React, { useState, useEffect } from "react";
import ScoreLeft from "../ScoreLeft/ScoreLeft.js";
import ScoreRight from "../ScoreRight/ScoreRight.js";
import { Wrapper } from '../../Student/StudentMain/WrapperStyle.js';
import { getStudentData } from '../../../services/student-service';
import Container from '../../Container';


const WHITE_COLOR = '#F4F4F4';
const BLACK_COLOR = '#343A40';

function ScoreMain() {

  const [currentRollNo, setCurrentRollNo] = useState('');
  const [course, setCourse] = useState([]);
  const [courseName, setCourseName] = useState('');
  const [studentsData, setStudentsData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(async  () => {
     await fetchStudentData();
  }, []);

  const fetchStudentData = async () => {
    setShowLoader(true);
    const res = await getStudentData();

    setShowLoader(false);
    setStudentsData(res.data);

  }


  return (
    <Wrapper>

      <Container  background={WHITE_COLOR} width='28%' height='76vh'>

        <ScoreLeft currentRollNo={currentRollNo}
                   currentCourse={course}
                   fetchStudentData={fetchStudentData}
                   courseName={courseName}
        />
      </Container>

      <Container background={BLACK_COLOR} width='70%' height='76vh'>


        <ScoreRight
          setCurrentRollNo={setCurrentRollNo}
          studentData={studentsData}
          showLoader={showLoader}
          setCourse = {setCourse}
          setCourseName={setCourseName}
        />
      </Container>
    </Wrapper >
  );
}

export default ScoreMain;
