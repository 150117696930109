import styled from 'styled-components';

export const ContainerStyled = styled.div`
overflow: auto;
    background: ${props => props.background};
    width: ${props => props.width};
    height: ${props => props.height};
    margin: ${props => typeof(props.margin) !== 'undefined' ? props.margin : '7px'};
    padding: ${props => typeof(props.padding) !== 'undefined' ? props.padding : '20px'};
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
`;  