import React, { useEffect, useState } from "react";


import loader from "../../../assets/loader.gif";

function ScoreRight({ setCurrentRollNo, studentData, showLoader, setCourse, setCourseName }) {
  const [activeRow, setActiveRow] = useState();
  const handleDownStudent = (roll_no, course, courseName) => {
    const subjects = course.subjects || [];
    let splittedArray  = [];
    if(subjects.length) {
      splittedArray = subjects.split(',');
    }
    setCurrentRollNo(roll_no);
    setCourseName(courseName)
    setCourse(splittedArray)
  };

  useEffect(() => {
    setActiveRow('');
    handleDownStudent('', [])
  }, [studentData])

  return (
    <div className=" px-5 pt-4 wrapper overflow-auto">
      {showLoader ? (
        <div className="loader">
          <img src={loader} alt="loader" className="loader-image" />
        </div>
      ) : (
          <table className="table table-borderless text-center">
            <thead>
              <tr className="border-th">
                <th scope="col">roll no</th>

                <th scope="col">student name</th>
                <th scope="col">father name </th>
              </tr>

            </thead>
            <tbody>
              {studentData.map((row, index) => (
                <tr className={`cursor-pointer  ${activeRow === index ? 'bg-blue' : null}`} onClick={() => {
                  handleDownStudent(row.roll_no, row.course, row.course.courseName);
                  setActiveRow(index)
                }}>
                  <td
                    className="Continent-cursor"

                  >
                    {row.roll_no}
                  </td>


                  <td>{row.name}</td>
                  <td>{row.father_name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
    </div>
  );
}

export default ScoreRight;
