import React from "react";
import Navbar from "./Components/Navbar/Navbar";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Verification from "./Views/verify";
import Coursesoffered from "./Views/CoursesOffered";
import DirectorMessage from "./Views/DirectorMessage";
import Vision_and_Mission from "./Views/Vision_and_Mission";
import AboutUs from "./Views/AboutUs";
import 'antd/dist/antd.css';
import AdminRouter from "./AdminRouter";
import ContactUsPage from "./Views/ContactUsPage"
import Homepage from "./Views/HomePage";
import HowtogetAffiliationPage from "./Views/HowtogetAffiliationPage";
import StudentRegistrationPage from "./Views/StudentRegistrationPage";
import EnquiryPage from "./Views/EnquiryPage";
import FranchiseeEnquiry from "./Views/FranchiseeEnquiry";
import Footer from "./Views/Footer";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    <div>

      <Router>
        <ToastContainer/>
          < Navbar />
        <Switch>
            <Route path="/admin" component={AdminRouter} />
            <Route path="/contactus" component={ContactUsPage} />


            <Route path="/Courses_Offered" component={Coursesoffered} />
          <Route path="/verification" component={Verification} />
          <Route path="/Director_Message" component={DirectorMessage} />
          <Route path="/Vision_and_Mission" component={Vision_and_Mission} />
          <Route path="/About_FIA" component={AboutUs} />
          <Route path="/howtogetaffiliation" component={HowtogetAffiliationPage} />
          <Route path="/studentregistration" component={StudentRegistrationPage} />
          <Route path="/franchiseeenquiry" component={FranchiseeEnquiry} />
          <Route path="/enquiry" component={EnquiryPage} />

          <Route path="/" component={Homepage}/>


        </Switch>


      </Router>

    </div>
  );
}

export default App;
