import React from "react";
import Hr from "../Helper/Hr/Hr";
import "./style.css";
import Directorimg from "../../assets/DirectorMessage/Director_img.jpg"

function DirectorsMessage() {
  return (
    <div>
      <div className="row m-5 row-reverse">
        <div className="col-lg-7 col-md-12 text-justify">
          <h2 className="text-center">Director's Message</h2>
          <h4 className="text-center">(Seema Goyal)</h4>

          <Hr />
          <div className="mt-5">
            <p>
              The last decade in the global arena has witness a tremendous
              growth in the area of information technology. Rapid advances in
              the technologies for communication media like television,
              computer, internet, printing and publishing has enabled us to get
              prompt access to required information.
            </p>

            <p>
              Information Technology (IT) has become one of the most robust
              industries in the world. It, more than any other industry or
              economic facet, has increased productivity, particularly in the
              developed world, and therefore is a key driver of global economic
              growth.
            </p>

            <p>
              The IT sector has emerged as a major global source of both growth
              and employment. IT Industry in the country has played a major role
              in placing India on the international map. The Indian IT Industry
              mainly comprises of instance System Integration, Software
              experiments, Custom Application Development and Maintenance
              (CADM), network services and IT Solutions.
            </p>

            <p>
              I strongly believe that at FIA, our true strength lies in the
              quality of our people, many of whom have been with us and consider
              this organization to be a part of their family. Today I am proud
              to say that we have created a work force whose loyalty and hard
              work are prevailing across the organization. Going forward too,
              our employees' passion to excel will continue to be the backbone
              of the FIA. With the launching of “Digital India” initiative by
              the Government of India, the role of FIA is likely to grow
              substantially in the near future as the demand for IT services and
              is expected to rise rapidly.
            </p>

            <p>
              Future IT Academy is to rededicate itself on strengthening its
              services with complete fairness, transparency and objectivity with
              a view to ensure timely execution of projects.
            </p>
          </div>
        </div>

        <div className="col-lg-5 col-md-12 d-flex justify-content-center align-items-center">

            <img className="Directorimg row-reverse-margin" src={Directorimg} alt=""></img>
            
        </div>
      </div>
    </div>
  );
}

export default DirectorsMessage;
