import axios from 'axios';
import config from '../../../config';


const SCORE_API_URL = `${config.API_URL}/score`;



  export const updateScore = (original_roll_no, score) => {
    const username = localStorage.getItem('username');
    const password = localStorage.getItem('password');
    return axios.patch(
        SCORE_API_URL,
      {
        original_roll_no: original_roll_no,
          score: score,
      },
      {
        headers: {
          username,
          password
        },
      }
    );
  };
