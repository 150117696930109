import React from 'react';
import StudentMain from  '../StudentMain/index.js';



function StudentWrapperRight() {

    return (

            <StudentMain />

    );
}
export default StudentWrapperRight;