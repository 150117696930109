import axios from 'axios';
import config from '../../../config';

const COURSE_API_URL = `${config.API_URL}/course`

export const getCourseData = () => {
  const username = localStorage.getItem('username');
  const password = localStorage.getItem('password');
  return axios.get(`${COURSE_API_URL}/all`, {
    headers: {
      username,
      password
    },
  });
};

export const createCourse = async (courseName, subjects) => {
  const username = localStorage.getItem('username');
  const password = localStorage.getItem('password');
  return axios.post(
    COURSE_API_URL,
    {
      courseName: courseName,
      subjects: subjects,
    },
    {
      headers: {
        username,
        password
      },
    }
  );
};

export const deleteCourse = (courseName) => {
  const username = localStorage.getItem('username');
  const password = localStorage.getItem('password');
  return axios.delete(COURSE_API_URL, {
    headers: {
      username,
      password
    },
    data: {
      courseName: courseName,
    },
  });
};

export const updateCourse = async (originalCouseName, courseName, subjects) => {
  const username = localStorage.getItem('username');
  const password = localStorage.getItem('password');
  return axios.patch(

    COURSE_API_URL,
    {
     originalCourseName: originalCouseName,
      courseName: courseName,
      subjects: subjects,
    },
    {
    headers: {
        username,
        password
  },
    }
  );
};
