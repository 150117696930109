import React from "react";
import Homepageimage from "../../assets/homepage/homepageimage.svg";
import "./style.css"
import secondslide from "../../assets/HomepageSlider/1.jpg";
import thirdslide from "../../assets/HomepageSlider/2.jpg";
import firstslide from "../../assets/HomepageSlider/3.jpg";
import fourthslide from "../../assets/HomepageSlider/4.jpg";
import fivthslide from "../../assets/HomepageSlider/5.jpg";


function HomepageSlider(){

return(

<div>

{/* image and logo */}
<div className="row mx-2 mb-2 homepageslider">

{/* <div classNameName=""></div>
<div classNameName="col-12 p-2 homepageslide">
    <img classNameName=" " src={homepageslide} alt=""/>
</div> */}


<div id="carouselExampleIndicators " className="carousel slide slider-height" data-ride="carousel">
<ol className="carousel-indicators">
    <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
  </ol>
  <div className="carousel-inner slider-height">
    <div className="carousel-item active" data-interval="2500">
      <img className="d-block w-100 slider-height" src={firstslide} alt="First slide"/>
    </div>
    <div className="carousel-item" data-interval="2500">
      <img className="d-block w-100 slider-height" src={secondslide} alt="Second slide"/>
    </div>
    <div className="carousel-item" data-interval="2500">
      <img className="d-block w-100 slider-height" src={thirdslide} alt="Third slide"/>
    </div>
    <div className="carousel-item" data-interval="2500">
      <img className="d-block w-100 slider-height" src={fourthslide} alt="Third slide"/>
    </div>
    <div className="carousel-item" data-interval="2500">
      <img className="d-block w-100 slider-height" src={fivthslide} alt="Third slide"/>
    </div>
  </div>
  <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="sr-only">Previous</span>
  </a>
  <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="sr-only">Next</span>
  </a>
</div>

</div>


</div>

);

}

export default HomepageSlider;
