import styled from 'styled-components';


export const Wrapper = styled.div`
    display: flex;
    margin-top: 96px;
    margin-left: 22px;
    width: ${props=> props.notFull ? 'auto' : '100%' }

`;
