import React from "react";
import Hr from "../Helper/Hr/Hr";
import "./style.css"

function Welcome_Contact(){


    return(

<div>
        <div>

        {/* welcome */}
<div className="row mx-0">
 
 <h1 className="Welcome_heading mt-1 text-center">Welcome to Future IT Academy</h1>


</div>

{/* <Hr /> */}
</div>
{/* <div className="row mx-0">

<div className="col-6 text-center d-flex align-self-center justify-content-center">
<h2>Contact Us At</h2>

</div>

<div className="col-6 text-left contact-formatting d-flex align-center justify-content-center border-left">
<div>
<h2>+91 7417367384</h2>
<h2>seemagoyal12345@gmail.com</h2>
</div>
</div>



</div> */}


</div>

    );
}

export default Welcome_Contact;