import React, { useEffect, useState } from "react";
import {getCourseData} from '../../../services/course-service';
import { addStudent, deleteStudent, updateStudent } from '../../../services/student-service';
import ImageUpload from '../../ImageUpload';
import { ImageUploadWrrapper } from './style.js';
import CustomButton from '../../Button';
import Label from '../../Label';
import { Modal, Button } from "react-bootstrap";
import DragAndDropImageLeft from '../../../assets/drag_and_drop_1.png';
import DragAndDropImageRight from '../../../assets/drag_and_drop.png';
import lodash from 'lodash';
import { imageToFile } from '../../../utils/image_to_file';
import default_icon_leko from '../../../assets/default_icon_leko.svg';
import './style.css';
//import DatePicker from 'react-date-picker';
import Dropdown from 'react-bootstrap/Dropdown'
import { DatePicker, Space } from 'antd';
import moment from 'moment';




function AppLeft({ selectedData, refetch }) {
  const [validData, setValidData] = useState(false);
  const [data, setData] = useState(selectedData || {});
  const [showItemExistingModal, setItemExistingModal] = useState(false);
  const [selectedImageState, setSelectedImageState] = useState('');
  const [imageModified, setImageModified] = useState(false);
  const [courses, setCourses] = useState([]);

  useEffect(async ()=>{
    const res = await getCourseData();
    setCourses(res.data)

  }, []);

  //console.log('selected data changed', selectedData.image);
  useEffect(() => {
    setSelectedImageState(selectedData.image);
  }, [selectedData], selectedData.image);


  const isValid = (values) => {
    for (let i = 0; i < values.length; i++) {

      if (!values[i]) {
        setValidData(false)
        return;
      }
    }
    setValidData(true);

  }

  const dataDiff = () => {
    const filteredData = lodash.pickBy(data, (value, key) => key !== 'image');
    const filteredSelectedData = lodash.pickBy(selectedData, (value, key) => key !== 'image');
    return lodash.isEqual(filteredData, filteredSelectedData) && !imageModified;
  }


  useEffect(() => {
    const values = lodash.values(data);
    isValid(values);

  }, [data]);


  useEffect(() => {
    window.lodash = lodash;
    setData({ ...selectedData });

  }, [selectedData]);

  const handleImage = (image, type) => {
    if (type === 'update') {
      setData({ ...selectedData, image: image })
    }
    else {
      setData({ ...data, image: image })
    }
  }

  const handleAddData = async () => {
    try {
      const res = await addStudent(data);
    }
    catch (err) {
      setItemExistingModal(true);
    }
    refetch();
    setData({});
    setImageModified(false)
    setSelectedImageState(default_icon_leko);

  };
  const handleDeleteData = async () => {
    const res = await deleteStudent(data.roll_no);

    setData({});
    setImageModified(false);
    refetch();


  };

  const handleUpdateData = async () => {
    try {
      const res = await updateStudent(selectedData.roll_no, data);
    }
    catch (err) {

      setItemExistingModal(true);
    }
    refetch();
    setData({});
    setImageModified(false)
    setSelectedImageState(default_icon_leko);

  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [startDate, setStartDate] = useState(new Date());
  return (
    <div className="d-flex flex-column justify-content-between height-100 overflow-auto">
      <div>
        <div className="Facility-left-position font-weight-bold">
          <div className="d-flex align-items-center justify-content-centerx">
            <ImageUploadWrrapper>
              <div className='image-wrapper' >
                <ImageUpload
                handleImage={handleImage}
                selectedImage={selectedImageState}
                selectedData={selectedData}
                setImageModified={setImageModified} />
              </div>




            </ImageUploadWrrapper>

          </div>

          <div>
            <p className="mx-1 mb-1 mt-3">Roll No.</p>

            <div class="form-group">
              <input
                type="text"
                class="input-formatting input-box"
                placeholder='Roll. No.'
                onChange={(e) => setData({ ...data, roll_no: e.target.value })}
                value={data.roll_no || ''}
              />

            </div>
          </div>
          <div>
            <p className="mx-1 mb-1 mt-3">Name</p>

            <div class="form-group">
            <input
                type="text"
                class="input-formatting input-box"
                placeholder='Name'
                onChange={(e) => setData({ ...data, name: e.target.value })}
                value={data.name || ''}
              />
            </div>
          </div>
          <div>
            <p className="mx-1 mb-1 mt-3">Course Name</p>

            <div class="form-group">

            <select
              name="courses"
              className="custom-select input-formatting input-box"
              id="inputGroupSelect01"
              onChange={(e) => setData({ ...data, courseName: e.target.value })}
              value={data.courseName}
            >
              <option >
                Select
              </option>

              {courses.map((option) => (
                <option
                  value={option.courseName}
                >
                  {option.courseName}
                </option>
              ))}
            </select>


            </div>
          </div>
          <div>
            <p className="mx-1 mb-1 mt-3">Father Name</p>

            <div class="form-group">
              <input
                type="text"
                class="input-formatting input-box"
                placeholder='Father Name'
                onChange={(e) => setData({ ...data, father_name: e.target.value })}
                value={data.father_name || ''}
              />

            </div>
          </div>
          <div>
            <p className="mx-1 mb-1 mt-3">Mother Name</p>

            <div class="form-group">
              <input
                type="text"
                class="input-formatting input-box"
                placeholder='Mother Name'
                onChange={(e) => setData({ ...data, mother_name: e.target.value })}
                value={data.mother_name || ''}
              />

            </div>
          </div>

          <div>
            <p className="mx-1 mb-1 mt-3">DOB</p>

            <div class="form-group">
            <DatePicker
                className="input-box"
                value= {data.dob ? moment(data.dob, 'YYYY-MM-DD') : moment()}
                onChange={(date, dateString) => setData({ ...data, dob: dateString})}
            />

            </div>
          </div>

          <div>
            <p className="mx-1 mb-1 mt-3">Address</p>

            <div class="form-group">
              <input
                type="text"
                class="input-formatting input-box"
                placeholder='Address'
                onChange={(e) => setData({ ...data, address: e.target.value })}
                value={data.address || ''}
              />

            </div>
          </div>

          <div>
            <p className="mx-1 mb-1 mt-3">Mob. No.</p>

            <div class="form-group">




              <input
                type="text"
                class="input-formatting input-box"
                placeholder='Mob. No.'
                onChange={(e) => setData({ ...data, mob_no: e.target.value })}
                value={data.mob_no || ''}
              />

            </div>
          </div>







          <div className="d-flex flex-row justify-content-between">
            <button
              type="button"
              className={`btn btn-black btn-sm px-4 shadow ${!validData || lodash.isEqual(selectedData, data) ? "not-allowed" : null
                }`}
              onClick={handleAddData}
              disabled={!validData || (lodash.isEqual(selectedData, data))}
            >
              Add
            </button>

            <>
              <button
                type="button"
                className={`btn btn-black btn-sm px-4 shadow ${!validData || (!dataDiff()) ? "not-allowed" : null
                  }`}
                onClick={handleShow}
                disabled={!validData || !dataDiff()}
              >
                Delete
              </button>
              <Modal show={showItemExistingModal} onHide={() => { setItemExistingModal(false) }}>
                <Modal.Header closeButton>
                  <Modal.Title>Already Exist</Modal.Title>
                </Modal.Header>
                <Modal.Body>This record is already exist</Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    onClick={() => {
                      setItemExistingModal(false)
                    }}
                  >
                    OK
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>You really want to delete this item?</Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      handleClose();
                      handleDeleteData();
                    }}
                  >
                    Delete
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
            <button
              type="button"
              className={`btn btn-green btn-sm px-4 shadow ${!validData || dataDiff() ? "not-allowed" : null
                }`}
              onClick={handleUpdateData}
              disabled={!validData || dataDiff()}
            >
              Modify
            </button>
            <button
          type="button"
          className="btn btn-green btn-sm px-3 shadow"
          onClick={() => {
            setData({});

            refetch();
          }}
        >
          Deselect
        </button>
          </div>
        </div>
      </div>
      <div>

      </div>
    </div>
  );
}

export default AppLeft;
