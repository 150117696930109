import React from "react";
import Courses from "../Components/Courses/Courses";
import Footer from "../Components/Footer/Footer";
import HomepageSlider from "../Components/HomepageSlider/HomepageSlider";


function Coursesoffered(){


return(


<div>
    <HomepageSlider />
    <Courses />
    <Footer />
</div>

);



}

export default Coursesoffered;