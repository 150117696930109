


import React from "react";
import Footer from "../Components/Footer/Footer";
import HomepageSlider from "../Components/HomepageSlider/HomepageSlider";
import HowtogetAffiliation from "../Components/HowtogetAffiliation/HowtogetAffiliation";


function HowtogetAffiliationPage(){


return(

<div>
    <HomepageSlider />
<HowtogetAffiliation/>
<Footer/>
</div>

);



}

export default HowtogetAffiliationPage;