
import React from "react";
import AboutUsComp from "../Components/AboutUsComp/AboutUsComp";
import Footer from "../Components/Footer/Footer";
import HomepageSlider from "../Components/HomepageSlider/HomepageSlider";



function AboutUs(){


return(

<div>
    <HomepageSlider />
 <AboutUsComp />
<Footer />
</div>

);



}

export default AboutUs;