import React from "react";
import "./style.css";


function ContactUs() {
  return (
  <div>
      
      
     
<section className="section section-padding m-5 shadow-lg ">

 
  <h2 className="section-heading h2 contact-text-heading text-center">Contact us</h2>


  <div className="row flex-row">

  
    <div className="col-lg-5 col-md-12 mb-4" >

    
      <div className="card border-primary">

        <div className="card-body">
        
          <div className="form-header blue accent-1">
            <h3>Future IT Academy</h3>
          </div>

          
          <br/>

       
          <div className="form-header blue accent-1">
            <h5 className="text-justify"><i className="fas fa-map-marked-alt contactus-icon"></i> Near Fuwara Chowk, Station Road, City - Chandpur, District- Bijnor, State- Uttar Pradesh, India (246725)</h5>
          </div>

          
          <br/>

          
          
          <br/>

         

          
        

          
          <div className="row text-center">
        

        <div className="col-md-12">
          <a className="btn-floating contactus-icon accent-1"><i className="fas fa-phone"></i></a>
          <h5>+91 7417367384</h5>
          <h6>Mon - Sat, 09:00-18:00</h6>
        </div>

        
         
       
      </div>
         

<br/>

      <div className="row text-center">
        

      

        <div className="col-md-12">
          <a className="btn-floating contactus-icon accent-1"><i className="fas fa-envelope"></i></a>
          <h5>seemagoyal12345@gmail.com</h5>
         
        </div>
      </div>
         
         

          

         

        </div>

      </div>
   

    </div>
  

  
    <div className="col-lg-7 col-md-12">

    
      <div id="map-container-google-11" className=" z-depth-1-half map-container-6" >
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13940.078114789332!2d78.26728959804714!3d29.134614416901403!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390b97cb3fd05b2b%3A0xfcdf3474e49d553b!2sRAJEEV%20GANDHI%20COMPUTERS!5e0!3m2!1sen!2sin!4v1643568239910!5m2!1sen!2sin"
          frameborder="0"  allowfullscreen ></iframe>


      </div>

      <br/>
    
     

    </div>
    

  </div>

</section>


  </div>
  );
}

export default ContactUs;
