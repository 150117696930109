
import React from "react";


import ContactUs from "../Components/ContactUs/ContactUs";
import Footer from "../Components/Footer/Footer";

function ContactUsPage(){


return(

<div>

   <ContactUs/>
   <Footer />
</div>

);



}

export default ContactUsPage;