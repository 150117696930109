import React, { useState, useEffect } from "react";
import CourseLeft from "../CourseLeft/index.js";
import CourseRight from "../CourseRight/index.js";
import { Wrapper } from '../../Student/StudentMain/WrapperStyle';
import { getCourseData } from '../../../services/course-service';
import Container from '../../Container';

const WHITE_COLOR = '#F4F4F4';
const BLACK_COLOR = '#343A40';

function CourseMain() {

  const [currentCourse, setCurrentCourse] = useState('');
  const [selectedSubjects, setSelectedSubjects] = useState('');
  const [courseData, setCourseData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    fetchCourseData()
  }, []);


  const fetchCourseData = async () => {
    setShowLoader(true);
    const res = await getCourseData();
    console.log('response of courses', res);
    setShowLoader(false);
    setCourseData(res.data);

  }


  return (

    <Wrapper>
      <Container background={WHITE_COLOR} width='28%' height='76vh'>
        <CourseLeft currentCourse={currentCourse} selectedSubjects={selectedSubjects} getCountriesData={fetchCourseData} />
      </Container>

      <Container background={BLACK_COLOR} width='70%' height='76vh'>
        <CourseRight
          setSelectedSubjects={setSelectedSubjects}
          setCurrentCourse={setCurrentCourse}
          courseData={courseData}
          showLoader={showLoader}
        />
      </Container>
    </Wrapper>
  );
}

export default CourseMain;
