import React, { useState, useEffect, useRef } from 'react';
import defaultApp from '../../assets/defaultApp.png'
import lekoIcon from '../../assets/default_icon_leko.svg';
import { ImageContainer } from './style';
import plusIcon from '../../assets/plus-icon.svg';
import plusGreen from '../../assets/plus_green.png'

function ImageUpload({ handleImage, selectedImage, selectedData, setImageModified }) {
  const [imageUrl, setImageUrl] = useState(selectedImage);

  const handleImageUpload = (event) => {
    const generatedUrl = URL.createObjectURL(event.target.files[0]);
    console.log('image url', event.target.files[0])
    handleImage(event.target.files[0]);
    setImageUrl(generatedUrl)
    setImageModified(true)
  };

  useEffect(() => {
   // console.log('selected image, in upload image', selectedImage);
      fetch(`${selectedImage}?x-request-html`).then(res => res.blob()).then(blob => {
        const file = new File([blob], "image.png", {
          type: 'image/png'
        });
        setImageUrl(selectedImage);
        handleImage(file, 'update');
       // console.log(file);
      })


  }, [selectedData, selectedImage]);



  return (
    <div>
      <input type="file" accept="image/*" id="actual-btn" onChange={handleImageUpload} hidden />
      <label for="actual-btn">

        <ImageContainer  >
          {imageUrl ?
            <div className='image-back'>
              <img src={imageUrl} alt='image-url' className='picked-image' />
              <img className='plus-icon' src={plusGreen} alt='plus-icon' />
            </div>
            :
            <div className='image-picker'>
              <img className='plus-icon' src={plusGreen} alt='plus-icon' />
            </div>
          }

        </ImageContainer>
      </label>
    </div>
  )
}

export default ImageUpload;