

import React from 'react';
import StudentWrapper from '../../Components/AdminComp/Components/Student/StudentWrapper';
import SliderPanel from "../../Components/AdminComp/Components/SliderPanel/SliderPanel";


function StudentData() {

    return(

        <div>
            <SliderPanel />
            <StudentWrapper />
        </div>
    );


}
export default StudentData;
