import React, { useEffect, useState } from "react";
import "./style.css"
import { useHistory } from 'react-router-dom';
import logo from '../../assets/FIALOGO.jpg'
import { useLocation } from "react-router-dom";

function Navbar() {
  const [isLoggedIn, setIsLoggedIn] = useState();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (localStorage.getItem('username') && localStorage.getItem('password')) {
      setIsLoggedIn(true);

    } else {
      setIsLoggedIn(false)
    }


  }, [location.pathname]);
  const handleStudentVerification = () => {

    history.push('/verification');
  }

  const handleLogout = () => {
    localStorage.removeItem('username');
    localStorage.removeItem('password');
    history.push('/');
  }

  return (

    <div>
      <div className="d-flex justify-content-center flex-wrap navbar-margin" style={{ backgroundColor: "#e3f2fd" }}>
        <nav
          className="navbar navbar-expand-lg py-4 navbar-light font-weight-bold fixed-top justify-content-center"
          style={{ backgroundColor: "#e3f2fd" }}>

          {/* navbar logo */}
          <div></div>
            <div>
              <a className="navbar-brand" href="/#">
                <img src={logo} className="navbarlogo" height={50} alt="" />
              </a>

              <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
              aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            </div>
           

        


          {/* navbar links */}


          <div class="collapse navbar-collapse flex-grow-0" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item active">
                <a className="nav-link" href="/#">Home <span className="sr-only">(current)</span></a>
              </li>

              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="/#" id="navbarDropdownMenuLink" role="button"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  About us
                                </a>
                <div class="dropdown-menu dropdown-menu-navbar animate slideIn" aria-labelledby="navbarDropdownMenuLink">
                  <a class="dropdown-item" href="/About_FIA">About FIA</a>
                  <a class="dropdown-item" href="/Director_Message">Director's Message</a>
                  <a class="dropdown-item" href="/Vision_and_Mission">Vision and Mission</a>
                </div>
              </li>

              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="/#" id="Studentcornerdropdown" role="button"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Student's Corner
                                </a>
                <div class="dropdown-menu dropdown-menu-navbar animate slideIn" aria-labelledby="Studentcornerdropdown">
                  <a class="dropdown-item" href="/studentregistration">Registration</a>
                  <a class="dropdown-item" href="/verification">Certificate Verification</a>
                </div>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="/Courses_Offered">Courses Offered</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/contactus">Contact Us</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/enquiry">Enquiry</a>
              </li>

              <li>
                <button type="button" onClick={handleStudentVerification}
                  class="btn btn-homepage rounded-pill px-2 fw">Student Verification
                                </button>
              </li>
              {isLoggedIn ?
                <li>
                  <button type="button" onClick={handleLogout}
                    className="btn btn-homepage rounded-pill px-2 fw cursor-pointer ml-4">Logout
                                    </button>
                </li> : null
              }

            </ul>
          </div>

        </nav>
      </div>
    </div>
  );


}

export default Navbar;
