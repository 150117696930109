import React, { useEffect, useState } from "react";
import default_icon_leko from '../../../assets/default_icon_leko.svg';
import loader from "../../../assets/loader.gif";
import './style.css';
import config from '../../../../../config'

function AppRight({ setSelectedData, studentData, showLoader }) {
  const [activeRow, setActiveRow] = useState();
  const handleDownStudent = (data) => {
    setSelectedData({
      name: data.name,
      roll_no: data.roll_no,
      father_name: data.father_name,
      image: data.image ? `${config.BASE_URL}/images/${data.image}` : 'https://lekolab-image.s3-eu-west-1.amazonaws.com/default_icon_leko.png',
      mother_name: data.mother_name,
      dob: data.dob,
      address: data.address,
      mob_no: data.mob_no,
      courseName: data.course ? data.course.courseName : ''
    });

  };

  useEffect(() => {
    setActiveRow('');
    handleDownStudent('', '')
  }, [studentData])

  return (
    <div className=" px-5 pt-4 wrapper">
      {showLoader ? (
        <div className="loader">
          <img src={loader} alt="loader" className="loader-image" />
        </div>
      ) : (
        <table className="table table-borderless text-center">
          <thead>
            <tr className="border-th">
              <th scope="col">Photo </th>
              <th scope="col">Roll No.</th>
              <th scope="col">Name</th>
              <th scope="col">Course Name</th>
              <th scope="col">Father Name</th>
              <th scope="col">Mother Name</th>
              <th scope="col">DOB</th>
              <th scope="col">Address</th>
             <th scope="col">Mob. No.</th>

            </tr>

            <div></div>
          </thead>
          <tbody>
            {studentData.map((row, index) => (
              <tr className={`cursor-pointer  ${activeRow === index ? 'bg-blue' : null}`} key=
                {index} onClick={() => {
                  handleDownStudent(row);
                  setActiveRow(index)
                }}>


                <td> <img src={row.image ? `${config.BASE_URL}/images/${row.image}` : default_icon_leko  } alt='app-img' style={{ width: '77px', height: '77px' }} /></td>
                <td>{row.roll_no}</td>
                <td>{row.name}</td>
                <td>{row.course.courseName}</td>
                <td> {row.father_name } </td>
                <td>{row.mother_name}</td>
                <td>{row.dob}</td>
                <td>{row.address}</td>
                <td>{row.mob_no}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default AppRight;
