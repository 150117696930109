import React from 'react';
import './style.css';

function Button({clickHandler, label, type}){
if(type=== 'gray'){
    return   <button
    type="button"
    className={`btn btn-gray btn-sm px-4 shadow margin-10 font-weight-bold`}
    onClick={clickHandler}
  >
    {label}
  </button>
}
 return (
    <button
    type="button"
    className={`btn btn-green btn-sm px-4 shadow margin-10 font-weight-bold`}
    onClick={clickHandler}
  >
    {label}
  </button>
 )

}

export default Button;