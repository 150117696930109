import React from "react";
import Hr from "../Helper/Hr/Hr";
import Aboutusslider from "../../assets/Aboutusslider.jpg";
import "./style.css";
import fialogo from "../../assets/fialogocircley.jpg";

function AboutUsComp() {
  return (
    <div>
      <div className="row m-5 row-reverse">
        <div className="col-lg-7 col-md-12 text-justify">
          <h2 className="text-center">About Future IT Academy</h2>

          <Hr />
          <div className="mt-5">
            <p>
              Future IT Academy (FIA) is one of the leading Organisation in
              Education Industry. FIA are in tune with the latest trends in the
              IT industry, thus setting the highest standards of IT education
              for the students. FIA provides learners with one of the most
              comprehensive, yet extended range of training options, with
              something virtually for every segment of society. In line with its
              vision of bringing people and computers together, FIA has built up
              a broad portfolio of computer training programs-from simple, short
              term courses for first time users, to advanced, high tech
              offerings for IT professionals, looking at re-skilling or
              upgrading their knowledge in IT.
            </p>

            <p>
              The company’s unique, proven methodologies governing course-ware,
              education delivery and extensive footprint in India, enable people
              from all parts of the country to gain and access high quality
              education in IT with an edge in the competitive training market.
              The FIA umbrella of courses covers almost every type of audience.
              Whether it’s a professional seeking to advance his knowledge for
              improving prospects at work, a student planning a career in IT or
              intending to use IT in his career, or a housewife / Sr. Citizen
              wanting to learn to send e-mail, FIA has something for everyone.
              Apart from IT professionals, FIA is now also focusing on creating
              skilled people for the IT-enabled services (ITES) / BPO market, an
              emerging opportunity for job seekers. The company’s move into the
              IT-assisted education space takes the FIA brand into new market
              segments, enabling it to touch the lives of millions of young
              people the world over. FIA has also tied up with leading
              educational institutions across the globe, to bridge the gap
              between the formal and non-formal learning environments.
            </p>
          </div>
        </div>
        <div className="col-lg-5 col-md-12 Aboutusslider d-flex justify-content-center align-items-center">


        <img className="fialogoa row-reverse-margin"  src={fialogo} alt="FIA logo"/>
        {/* <img className="Aboutussliderimg" src={Aboutusslider} alt=""></img> */}
        {/* <div className="eventoutercontainer  shadow-lg mt-5">
          <div className="eventheader text-center ">

            <h5 className="font-weight-bolder eventheadertext pt-2">Latest Updates</h5>
          </div>
        <marquee  className="p-4 text-justify" height="350px" direction="up" scrollamount="4" onmouseover="this.setAttribute('scrollamount', 0, 0);" onmouseout="this.setAttribute('scrollamount', 4, 0);">

<p>This is a sample scrolling text that has scrolls in the upper direction.</p>

<p>This is a sample scrolling text that has scrolls in the upper direction.</p>
<p>This is a sample scrolling text that has scrolls in the upper direction.</p>
<p>This is a sample scrolling text that has scrolls in the upper direction.</p>
<p>This is a sample scrolling text that has scrolls in the upper direction.</p>
<p>This is a sample scrolling text that has scrolls in the upper direction.</p>
<p>This is a sample scrolling text that has scrolls in the upper direction.</p>
<a href="/">dsfds</a>
</marquee>
</div> */}
        </div>
      </div>
    </div>
  );
}

export default AboutUsComp;
