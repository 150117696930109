import React, {useState} from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import Marksheet from "../Marksheet/Marksheet";

import Certificate from "../Certificate/Certificate";

function CertificateTab({certificateData}) {
  const [key, setKey] = useState('certificate');
  console.log('certificate data', certificateData.course);
  return (
      <div>
          <Tabs className="d-flex justify-content-center" defaultActiveKey="studentInfo" id="uncontrolled-tab-example"
                activeKey={key} onSelect={(k) => setKey(k)}>
            <Tab eventKey="certificate" title="Certificate">
              <Certificate name={certificateData.name}
                           father_name={certificateData.father_name}
                           course_name={certificateData.course}
                           roll_no={certificateData.roll_no}
                           photo={certificateData.image}/>
            </Tab>
            {certificateData.score ?
                <Tab eventKey="marksheet" title="Marksheet">
                  <Marksheet name={certificateData.name}
                             roll_no={certificateData.roll_no}
                             photo={certificateData.image}
                             score={certificateData.score || {subjects: []}}
                  />

                </Tab> : null
            }


          </Tabs>

    </div>
  );
}

export default CertificateTab;
