 import React from "react";
import "./style.css";
import { Accordion, Card } from "react-bootstrap";
import Course from "../Course/Course";

const CoursesEntries = [
  {id: '0', 
  courseName:"Advanced Diploma in Computer Application (ADCA)",
  courseCode:"F20101",
  eligibility:"8th",
  duration:"1 Year",
  semesters: [
    {id: 'Semester I', subjects: ["Operating System", "Computer Concept and Fundamentals", "MS-Office (MS-Word, MS- Excel, MS-Power Point, MS-Access)", "HTML & Front Page", "Lab-I"]},
    {id: 'Semester II', subjects: ["Basics of Financial Accounting", "Computerised Accounting Through Tally.ERP 9  (with GST)", " D.T.P (Page Maker, Corel Draw, Photoshop)", "Computer Network & Internet", "Lab-II", "Project Work"]}
  ]
 },

 {id: '1', 
  courseName:"Diploma in Computer Application (DCA)",
  courseCode:"F20102",
  eligibility:"8th",
  duration:"1 Year",
  semesters: [
    {id: 'Semester I', subjects: ["Operating System", "Computer Concept and Fundamentals", "MS-Office (MS-Word, MS- Excel, MS-Power Point, MS-Access)", "Computer Network & Internet", "Lab-I"]},
    {id: 'Semester II', subjects: ["Basics of Financial Accounting", "Computerised Accounting Through Tally.ERP 9 (with GST)", " D.T.P (Page Maker, Corel Draw, Photoshop)",  "Lab-II", "Project Work"]}
  ]
 },


 {id: '2', 
 courseName:"Diploma in Computer Application and Accounting (DCAA)",
 courseCode:"F20103",
 eligibility:"8th",
 duration:"6 Months",
 semesters: [
   {id: '', subjects: ["Computer Concept and Fundamentals", "MS-Office (MS-Word, MS- Excel, MS-Power Point, MS-Access)", "Introduction to Internet & Email", "Computerised Accounting Through Tally.ERP 9 (with GST)", "Lab-I", "Project Work"]},
   
 ]
},

{id: '3', 
courseName:"Certificate Course in Computer Accounting (CCCA)",
courseCode:"F20104",
eligibility:"10th",
duration:"3 Months",
semesters: [
  {id: '', subjects: ["Computer Concept and Fundamentals", "Introduction to Internet & Email", "Computerised Accounting Through Tally.ERP 9 (with GST)", "Lab-I", "Project Work"]},
  
]
},

{id: '4', 
 courseName:"Certificate Course in Office Automation & Internet (CCOAI)",
 courseCode:"F20105",
 eligibility:"8th",
 duration:"3 Months",
 semesters: [
   {id: '', subjects: ["Computer Concept and Fundamentals", "MS-Office (MS-Word, MS- Excel, MS-Power Point, MS-Access)", "Introduction to Internet & Email", "Lab-I", "Project Work"]},
   
 ]
},



{id: '5', 
 courseName:"Certificate Course in Desk Top Publication (DTP)",
 courseCode:"F20106",
 eligibility:"8th",
 duration:"4 Months",
 semesters: [
   {id: '', subjects: ["Computer Concept and Fundamentals", "English and Hindi Typing", "Introduction to Internet & Email",  "D.T.P (Page Maker, Corel Draw, Photoshop)", "Introduction to Internet & Email", "Lab-I", "Project Work"]},
   
 ]
},


{id: '6', 
 courseName:"Internet Kiosk Operator (IKO)",
 courseCode:"F20107",
 eligibility:"8th",
 duration:"6 Months",
 semesters: [
   {id: '', subjects: ["Computer Concept and Fundamentals","Operating Various Devices like Printers/ Scanners/ Data Services" , "MS-Office (MS-Word, MS- Excel, MS-Power Point)", "Internet & Email",  "Adobe Photoshop", "Filling Online Application Forms ", "Lab-I", "Project Work"]},
   
 ]
}




];

function Courses() {
  return (
    <div className="row my-5">
       <h1 className="my-5 mx-auto">Click on course name for more details</h1>
       {
         CoursesEntries.map(courses=>(
          <Accordion className="courses-row">

          <Card className="cursor-courses">
            <Accordion.Toggle as={Card.Header} eventKey={courses.id}>
         <h2>{courses.courseName}</h2>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={courses.id}>
              <Card.Body>
                <Course data={courses} />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          
            </Accordion>
         ))

       }
   
          
    </div>
  );
}

export default Courses;
