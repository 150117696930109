import axios from 'axios';
import config from '../../../config';

const AUTHENTICATE_URL = `${config.API_URL}/login`;

export const authenticate = (username, password) => {
    return axios.post(`${AUTHENTICATE_URL}`, {
        username,
        password
    });
};

