import React from "react";
import Footer from "../Components/Footer/Footer";
import HomepageSlider from "../Components/HomepageSlider/HomepageSlider";



function EnquiryPage(){


return(
<div>

<div>
    
<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdDKGiv1mSc5-Fm9b9hXmb6kAQ8PvDTHpEPjs3ZkqxKziDeYA/viewform?embedded=true" width="100%" height="979" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe></div>
<Footer/>
</div>

);



}

export default EnquiryPage;