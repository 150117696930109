import styled from 'styled-components';



export const ImageUploadWrrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    .image-wrapper {
        margin-right: 23px;
    }
    .field-wrapper {
      width: 100%;
    }


`;