import React from "react";
import DirectorsMessage from "../Components/DirectorsMessage/DirectorsMessage";
import Footer from "../Components/Footer/Footer";
import HomepageSlider from "../Components/HomepageSlider/HomepageSlider";



function DirectorMessage(){


return(

<div>
   <HomepageSlider />
   <DirectorsMessage />
   <Footer />
</div>

);



}

export default DirectorMessage;