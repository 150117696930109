import React from 'react';

import LoginPage from '../../Components/AdminComp/LoginPage/LoginPage'

function AdminLogin() {

    return(

        <div style={{marginTop: '150px'}}>
           <LoginPage />

        </div>
    );


}
export default AdminLogin;
