import axios from 'axios';
import config from '../../../config';

const STUDENT_API_URL = `${config.API_URL}/student`;

export const getStudentData = () => {
  const username = localStorage.getItem('username');
  const password = localStorage.getItem('password');
  return axios.get(`${STUDENT_API_URL}/all`, {
    headers: {
      username,
      password
    },
  });
};

export const addStudent = (data) => {
  const formData = new FormData();
  const username = localStorage.getItem('username');
  const password = localStorage.getItem('password');
  formData.append('roll_no', data.roll_no);
  formData.append('name', data.name);
  formData.append('father_name', data.father_name);
  formData.append('mother_name', data.mother_name);
  formData.append('dob', data.dob);
  formData.append('image', data.image);
  formData.append('address', data.address);
  formData.append('courseName', data.courseName);
  formData.append('mob_no', data.mob_no);
  return axios.post(STUDENT_API_URL, formData, {
    headers: {
      username,
      password
    },
  })
}


export const deleteStudent = (roll_no) => {
  const username = localStorage.getItem('username');
  const password = localStorage.getItem('password');
  return axios.delete(STUDENT_API_URL, {
    headers: {
      username,
      password
    },
    data: {
      roll_no: roll_no,

    },
  });
};

export const updateStudent = (originalRollNo, data) => {
  const formData = new FormData();
  formData.append('originalRollNo', originalRollNo)
  formData.append('roll_no', data.roll_no);
  formData.append('name', data.name);
  formData.append('father_name', data.father_name);
  formData.append('mother_name', data.mother_name);
  formData.append('dob', data.dob);
  formData.append('image', data.image);
  formData.append('address', data.address);
  formData.append('courseName', data.courseName);
  formData.append('mob_no', data.mob_no);
  return axios.patch(
    STUDENT_API_URL,
    formData,
    {
      headers: {
        "x-api-key": '2TY8C84Srd3Va9ZC6RDLA49ZECP3ShnW9jFbmi0s',
      },
    }
  );
};
