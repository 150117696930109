import React from 'react';
import CourseMain from '../../Components/AdminComp/Components/Course/CourseMain';
import SliderPanel from "../../Components/AdminComp/Components/SliderPanel/SliderPanel";


function CoursesData() {

    return(

        <div>
            <SliderPanel />
           <CourseMain />

        </div>
    );


}
export default CoursesData;
