import React, {useEffect, useState} from 'react';
import {authenticate} from "../services/user-service";
import {useHistory} from "react-router-dom";
import "./style.css";

function LoginPage() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const history = useHistory();

    useEffect(()=>{
        const username = localStorage.getItem('username');
        const password = localStorage.getItem('password');
        if(username && password) {
            history.push('/admin/studentdata');
        }

    }, []);

    const handleOnLogin = () => {
        authenticate(username,password).then(res=> {
                if(res.data.message === 'authenticated'){
                    localStorage.setItem('username', username );
                    localStorage.setItem('password', password);
                    history.push('/admin/studentData')

                }


        })

    }

    return (

        <div className="container">
            <div className="row">
                <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                    <div
                        className={"login-form-text background-card card border-primary text-primary shadow-lg rounded-6 my-5"}>
                        <div className="card-body p-4 p-sm-5">
                            <h3 className="login-form-text card-title text-center mb-5 fw-light fs-5">Future IT
                                Academy</h3>
                                <div className="form-floating mb-3">
                                    <label for="floatingInput">Username</label>
                                    <input type="text" className="form-control" id="floatingInput"
                                           placeholder="Username"
                                           value={username}
                                           onChange={(e) => setUsername(e.target.value)}

                                    />

                                </div>
                                <div className="form-floating mb-3 ">
                                    <label for="floatingPassword">Password</label>
                                    <input type="password" className="form-control" id="floatingPassword"
                                           placeholder="Password"
                                           value={password}
                                           onChange={(e) => setPassword(e.target.value)}
                                    />

                                </div>


                                <div className="d-flex justify-content-center align-items-center">
                                    <button className="login-form-text btn btn-primary btn-login text-uppercase fw-bold"
                                            onClick={handleOnLogin}

                                    >Sign in

                                    </button>
                                </div>

                                <hr className="my-4 login-hr"/>



                            <div className="d-flex justify-content-end">
                                <a href="/#"
                                   className="login-form-text btn btn-secondary btn-login text-uppercase fw-bold"
                                   type="submit">Home</a>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
