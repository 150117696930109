import React, { useState } from 'react';
import VerificationForm from '../Components/Verification_Form/Verification_Form.jsx'
import Hr from "../Components/Helper/Hr/Hr"
import CertificateTab from "../Components/CertificateTab/CertificateTab"
import Footer from '../Components/Footer/Footer.jsx';

function Verification() {
    const [showCertificate, setShowCertificate] = useState(false);
    const [certificateData, setCertificateData] = useState({});

    return(
    <div>

        <div>

            
            <VerificationForm setShowCertificate={setShowCertificate} setCertificateData={setCertificateData} />
            {showCertificate ?
                <div>
                    <Hr />
                    <CertificateTab certificateData={certificateData} />
                </div>
                : null
            }
        </div>
        <Footer />
    </div>
    )

}

export default Verification;