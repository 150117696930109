import React from "react";
import StudentWrapperRight from "../StudentWrapperRight"


function StudentWrapper() {
  return (
    <div className="row m-0 ">
        <StudentWrapperRight />
    </div>
  );
}
export default StudentWrapperRight;
