import React from 'react';
import { ContainerStyled } from './style.js';


function Container({ width, background, height, children, padding, margin, ...props }) {
    return (<ContainerStyled width={width} background={background} height={height} padding={padding} margin={margin} >
        {children}
    </ContainerStyled>)
}

export default Container;