import React from "react";
import "./style.css";
import photo from "../../assets/certificate/photo.jpg";
import config from '../../config';
import passed from "../../assets/certificate/passed.jpg";

function Marksheet({name, roll_no, photo, score } ) {

  const calculateTotalMarks = (subjects) => {
    return subjects.reduce( (previousValue, currentValue) => {
      return previousValue + parseInt(currentValue.marks);
    }, 0);
  }

  return (
    <div>
      <div className="row  margin-table">
        {/*student photo*/}

        <div className="col-lg-3 col-md-12 d-flex justify-content-center align-self-center">
          <img className="studentinfo-img" src={`${config.BASE_URL}/images/${photo}`} alt=""></img>
        </div>

        {/*table */}

        <div className="col-lg-6 col-md-12 table-responsive my-5 ">
          <table class="table table-bordered text-dark">
            <tbody className="align-middle">

            <tr className="text-center table-blue ">
                <td
                  colspan="4"
                  className="align-middle font-weight-bolder marksheet-label-size"
                >
                  Marksheet
                </td>
              </tr>
              <tr >
                <td className="align-middle font-weight-bold column-width">
                  Student Name
                </td>
                <td className="align-middle column-width">{name}</td>
                <td className="align-middle font-weight-bold column-width">
                  Enrollment Number
                </td>
                <td className="align-middle column-width">{roll_no}</td>
              </tr>


              <tr className="text-center table-blue">
                <td colspan="2" className="align-middle font-weight-bold">
                  Subject Name
                </td>
                <td colspan="1" className="align-middle font-weight-bold">
                  Marks Obtain
                </td>
                <td colspan="1" className="align-middle font-weight-bold">
                  Total Marks
                </td>
              </tr>

              {/*subject and marks*/}
            {score.subjects.map(subject=>(
                <tr>
                  <td colSpan="2" className="align-middle font-weight-bold">
                    {subject.name}
                  </td>
                  <td colSpan="1" className="align-middle font-weight-normal">
                    {subject.marks}
                  </td>
                  <td colSpan="1" className="align-middle font-weight-normal">
                    100
                  </td>
                </tr>
            ))

            }


              <tr>
                <td colspan="2" className="align-middle font-weight-bold table-blue">
                  Grand Total
                </td>
                <td colspan="1" className="align-middle font-weight-bold">
                  {calculateTotalMarks(score.subjects)}
                </td>
                <td colspan="1" className="align-middle font-weight-bold">
                  {score.subjects.length*100 }
                </td>
              </tr>


            </tbody>
          </table>
        </div>

        {/*certified photo*/}

        <div className="col-lg-3 col-md-12 d-flex justify-content-center align-self-center">
          <img className="certified-img" src={passed} alt=""></img>
        </div>
      </div>
    </div>
  );
}

export default Marksheet;
