import React from "react";
import Hr from "../Helper/Hr/Hr";

import "./style.css";

import Visionandmission1 from "../../assets/Visionandmission/Visionandmission1.svg";

import Visionandmission2 from "../../assets/Visionandmission/Visionandmission2.svg";

function VisionMissionComp() {
  return (
    <div>
      <div className="row m-5 row-reverse ">
        <div className="col-lg-7 col-md-12  padding-vision text-justify">
          <h2 className="text-center">Vision and Mission</h2>

          <Hr />

          <div className="mt-5">
            <h3 className="my-3">Our Vision</h3>

            <p>
              Future IT Academy shall provide seamless access to sustainable and
              learner centric quality education, skill up gradation and training
              to all by using innovative technologies and methodologies for
              computer literacy programme and ensuring convergence of existing
              systems for massive human resources required for promoting
              integrated national development and global understanding. Thus we
              have :
            </p>

            <ul>
              <li>
                To establish computer literacy centers countrywide and abroad
                specially in rural areas.
              </li>
              <li>
                To impart learning, keeping in quality courses with study
                materials, well drafted keeping in view the students awareness
                level as well as the latest development as job oriented and all
                this for a normal fee.
              </li>
              <li>
                To extend financial flexibility in terms of scholarships and
                stipends to deserving candidates.
              </li>
              <li>To proved computer education to grassroots levels.</li>
            </ul>

            <h3 className="my-3">Our Mission</h3>

            <p>
              Our Mission is to build a strong research and teaching environment
              that responds to future challenges. Our mission is to provide
              quality computer education in both theoretical and applied
              foundations of computer science and train students to effectively.
              Apply this research based and originality oriented education to
              solve real world problems. Thus amplifying their potential &
              interpersonal for high quality career and give them competitive
              advantage in every challenging global work environment.
            </p>

            <ul>
              <li>
                Strengthen the development of associated learning centre as a
                proactive role model for high quality and learner centric
                computer literacy programmee.
              </li>
              <li>
                Share professional capabilities and resources to improve
                standards of computer education in the whole world.
              </li>
              <li>
                Develop networks using emerging technologies and methods with
                global reach for effective programme delivery.
              </li>
              <li>
                Provide an intelligent flexible system of education to meet the
                challenges of access and equity and work towards development of
                knowledge based society.
              </li>
              <li>
                Provide computer education to the highest unreached people and
                promote community participation for local development.
              </li>
              <li>
                Provide specific need-based computer education and training
                opportunities for continuous professional development and skill
                up gradation to in service professionals.
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-5 col-md-12 vision-slider-margin">
          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-ride="carousel"
            data-interval="1500"
          >
            <ol className="carousel-indicators">
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="0"
                class="active"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="1"
              ></li>
             
            </ol>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  className="d-block w-100 row-reverse-margin"
                  src={Visionandmission1}
                  alt="First slide"
                />
              </div>
              <div className="carousel-item">
                <img
                  className="d-block w-100"
                  src={Visionandmission2}
                  alt="Second slide"
                />
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VisionMissionComp;
