import React from 'react';
import { BrowserRouter as Router, Route, Switch, useRouteMatch } from "react-router-dom";
import StudentData from "./Views/Admin/StudentData";
import CoursesData from "./Views/Admin/CoursesData";
import Score from "./Views/Admin/Score";
import AdminLogin from "./Views/Admin/LoginPage";
import SliderPanel from "./Components/AdminComp/Components/SliderPanel/SliderPanel";


function AdminRouter() {
    let { path, url } = useRouteMatch();
    return (
     <>
     <Switch>
    <Route path={`${path}/studentdata`} component={StudentData} />
    <Route path={`${path}/coursesdata`} component={CoursesData} />
    <Route path={`${path}/score`} component={Score} />
    <Route path="/admin" component={AdminLogin} />
    </Switch>
    </>
)

}

export default AdminRouter;
