import React from "react";
import Hr from "../Helper/Hr/Hr";
import "./style.css"
import certificate from "../../assets/icon-slider/certification.png"
import student from "../../assets/icon-slider/graduated.png"
import admin from "../../assets/icon-slider/admin.png"
import affiliation from "../../assets/icon-slider/affiliate-marketing.png"
import enquiry from "../../assets/icon-slider/problem.png"
import coursesoffered from "../../assets/icon-slider/book-stack.png"

function QuickLinksSlider() {

    return (

        <div>
            <Hr />

            <div className="row mx-5">


                <div className="col-lg-2 col-md-6 border-icon text-center icon-animation ">

                    <a href="/studentregistration">
                        <img className="icon-size mb-2" src={student} alt="" />
                        <h6>Student Registration</h6>
                    </a>
                </div>



                <div className="col-lg-2 col-md-6  text-center icon-animation border-icon ">
                    <a href="/verification">
                        <img className=" icon-size mb-2" src={certificate} alt="" />
                        <h6>Certificate Verification</h6>
                    </a>
                </div>


                <div className="col-lg-2 col-md-6  text-center icon-animation border-icon ">
                    <a href="/Courses_offered">
                        <img className=" icon-size mb-2" src={coursesoffered} alt="" />
                        <h6>Courses Offered</h6>
                    </a>
                </div>


                <div className="col-lg-2 col-md-6  text-center icon-animation border-icon ">
                    <a href="/enquiry">
                    <img className=" icon-size mb-2 ml-3" src={enquiry} alt="" />
                    <h6>Enquiry</h6>
                    </a>
            </div>

            <div className="col-lg-2 col-md-6  text-center icon-animation border-icon ">
                <a href="/howtogetaffiliation">
                    <img className="icon-size mb-2 " src={affiliation} alt="" />
                    <h6>How to get affiliation</h6>
                </a>
            </div>

            <div className="col-lg-2 col-md-6  text-center icon-animation border-centerlogin">
                <a href="/admin">
                <img className=" icon-size mb-2" src={admin} alt="" />

                <h6>Center Login</h6>
                    </a>
            </div>
                
            </div>
            </div>

);

}

export default QuickLinksSlider;