import React from 'react';
import Footer from '../Components/Footer/Footer';
import HomepageSlider from '../Components/HomepageSlider/HomepageSlider';
import VisionMissionComp from "../Components/VisionMissionComp/VisionMissionComp"

function Vision_and_Mission (){


    return <div> 
    
   <HomepageSlider/>
    <VisionMissionComp />
    
    <Footer/>
     </div>

}

export default Vision_and_Mission;