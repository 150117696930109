import React from "react";
import Footer from "../Components/Footer/Footer";



function StudentRegistrationPage(){


return(
<div>
<div>
<iframe src="https://docs.google.com/forms/d/e/1FAIpQLScfrKDYw69iejJQg_lW2fnavA1hnzAHdT0HucJXoRxIvh07eA/viewform?embedded=true" width="100%" height="1114" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
</div>
<Footer/>
</div>

);



}

export default StudentRegistrationPage;