import React, { useEffect, useState } from "react";
import './style.css';

import {
  createCourse,
  deleteCourse,
  updateCourse,
} from "../../../services/course-service";
import { Modal, Button } from "react-bootstrap";

function CourseLeft({ currentCourse, selectedSubjects, getCountriesData }) {
  const [courseName, setCourseName] = useState(currentCourse);
  const [subjects, setSubjects] = useState(
    selectedSubjects
  );
  const [showItemExistingModal, setItemExistingModal] = useState(false);



  useEffect(() => {
    setCourseName(currentCourse);
    setSubjects(selectedSubjects);
  }, [currentCourse, selectedSubjects]);

  const handleAddCourse = async () => {
    try {
      const res = await createCourse(courseName, subjects);
    } catch (err) {
      console.log("err", err);
      setItemExistingModal(true);
    }

    getCountriesData();
    setCourseName("");
    setSubjects('');
  };
  const handleDeleteCourse = async () => {
    const res = await deleteCourse(currentCourse);
    console.log(res);
    getCountriesData();
    setCourseName("");
    setSubjects('');
  };

  const handleUpdateCourse = async () => {
    try {
      const res = await updateCourse(
        currentCourse,
        courseName,
        subjects
      );
    } catch (err) {
      setItemExistingModal(true);
    }

    getCountriesData();
    setCourseName("");
    setSubjects('');
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="d-flex flex-column justify-content-between height-100">
      <div>
        <div className="Facility-left-position font-weight-bold">
          <p className="mx-1 mb-1 mt-3">Course Name</p>

          <div class="form-group">
            <input
              type="text"
              className="input-formatting input-box"
              onChange={(e) => setCourseName(e.target.value)}
              value={courseName}
            />
          </div>

          <p className="m-1">Subject Names</p>

          <div className="form-group ">
          <input
              type="text"
              class="input-formatting input-box"
              onChange={(e) => setSubjects(e.target.value)}
              value={subjects}
            />
          </div>

          <div className="d-flex flex-row justify-content-between">
            <button
              type="button"
              className={`btn btn-black btn-sm px-4 shadow ${
                !(courseName && subjects) ||
                (currentCourse === courseName &&
                  selectedSubjects === subjects)
                  ? "not-allowed"
                  : null
              }`}
              onClick={handleAddCourse}
              disabled={
                !(courseName && subjects) ||
                (currentCourse === courseName &&
                  selectedSubjects === subjects)
              }
            >
              Add
            </button>

            <>
              <button
                type="button"
                className={`btn btn-black btn-sm px-4 shadow ${
                  !currentCourse.length ||
                  currentCourse !== courseName ||
                  selectedSubjects !== subjects
                    ? "not-allowed"
                    : null
                }`}
                onClick={handleShow}
                disabled={
                  !currentCourse.length ||
                  currentCourse !== courseName ||
                  selectedSubjects !== subjects
                }
              >
                Delete
              </button>

              <Modal
                show={showItemExistingModal}
                onHide={() => {
                  setItemExistingModal(false);
                }}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Already Exist</Modal.Title>
                </Modal.Header>
                <Modal.Body>This record is already exist</Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    onClick={() => {
                      setItemExistingModal(false);
                    }}
                  >
                    OK
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>You really want to delete this item?</Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      handleClose();
                      handleDeleteCourse();
                    }}
                  >
                    Delete
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
            <button
              type="button"
              className={`btn btn-green btn-sm px-4 shadow ${
                !(courseName && subjects) ||
                (currentCourse === courseName &&
                  selectedSubjects === subjects)
                  ? "not-allowed"
                  : null
              }`}
              onClick={handleUpdateCourse}
              disabled={
                !(courseName && subjects) ||
                (currentCourse === courseName &&
                  selectedSubjects === subjects)
              }
            >
              Modify
            </button>
            <button
          type="button"
          className="btn btn-green btn-sm px-3 shadow"
          onClick={() => {
            setCourseName("");
            setSubjects("");
            getCountriesData();
          }}
        >
          Deselect
        </button>
          </div>
        </div>
      </div>

    </div>
  );
}

export default CourseLeft;
