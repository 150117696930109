import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import "./style.css";
import appIcon from "../../../assets/app_icon.svg";
import ArrowUp from "../../../assets/ArrowUp.svg";
import ArrowDown from "../../../assets/ArrowDown.svg";
import continenticon from "../../../assets/continenticon.svg";
import countryicon from "../../../assets/countryicon.svg"
import facilityicon from "../../../assets/facilityicon.svg"
import creationIcon from '../../../assets/creationIcon.svg';
import roleIcon from '../../../assets/role_icon.svg';

function Sidebar({ toggle }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isFavoriteApp, setIsFavoriteApp] = useState(false);
  const [isApp, setIsApp] = useState(true);
  const [apps, setApps] = useState([

    {
      name: "Student Data",
      route: "/admin/studentdata",
      appIcon: roleIcon,
    },

    {
      name: "Score",
      route: '/admin/score',
      appIcon: creationIcon
    },




    {
      name: "Courses Data",
      route: '/admin/coursesdata',
      appIcon: creationIcon
    },

  ]);
  const [favoriteApps, setFavoriteApps] = useState([

  ]);

  const handleDownHandle = () => {
    setIsOpen(!isOpen);
  };

  const [isOpen2, setIsOpen2] = useState(false);

  const handleDownHandle2 = () => {
    setIsOpen2(!isOpen2);
  };

  const [isOpen3, setIsOpen3] = useState(false);

  const handleDownHandle3 = () => {
    setIsOpen3(!isOpen3);
  };

  const handleOnDragOver = (e) => {
    e.preventDefault();
  };

  const handleOnDrop = (e) => {
    let id = e.dataTransfer.getData("id");
    let draggedApp = e.dataTransfer.getData('app');
    if (id === 'app') {
      const filteredApps = apps.filter(app => app.name !== draggedApp);
      const appData = apps.find(app => app.name === draggedApp);
      console.log('filteredapps', filteredApps, appData)
      setApps(filteredApps);
      setFavoriteApps([...favoriteApps, appData])
    }
    else if (id === 'favorite') {
      const filteredApps = favoriteApps.filter(app => app.name !== draggedApp);
      const appData = favoriteApps.find(app => app.name === draggedApp);
      setFavoriteApps(filteredApps);
      setApps([...apps, appData])
    }
  };

  const handleDragStart = (e, type, app) => {
    if (type === "app") {
      e.dataTransfer.setData("id", "app");
      e.dataTransfer.setData("app", app)
    } else if (type === "favorite") {
      e.dataTransfer.setData("id", "favorite");
      e.dataTransfer.setData("app", app)
    }
  };

  const history = useHistory();
  return (
    <div className="Sidebar">
      <div className="accordion" id="accordionExample">
        <div className="card rounded">



          <div
            id="collapseTwo"
            className="collapse"
            aria-labelledby="headingTwo"
          >
            <div
              className="card-body background-color-card-header sidebar-accordian-heading"
              onDragOver={(e) => {
                handleOnDragOver(e);
              }}
              onDrop={(e) => {
                handleOnDrop(e);
              }}
            >
              {favoriteApps.map((app) => (
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(<Tooltip id="button-tooltip" className='custom-tooltip' >{app.name}</Tooltip>)}
                >
                  <img
                    src={app.appIcon}
                    alt={app.name}
                    width="50px"
                    draggable
                    className='m-1'
                    onDragStart={(e) => {
                      handleDragStart(e, 'favorite', app.name);
                    }}
                    onClick={() => {
                      history.push(app.route);
                      toggle();
                    }}
                  />
                </OverlayTrigger>
              ))}

              {isFavoriteApp ? (
                <div>
                  <img
                    src={appIcon}
                    alt="app-icon"
                    width="50px"
                    draggable
                    onDragStart={(e) => {
                      handleDragStart(e, "favorite");
                    }}
                    onClick={() => {
                      history.push("/dashboard/portal");
                      toggle();
                    }}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="card rounded">
          <div
            className="card-header background-color-card-header"
            id="headingOne"
          >
            <div className="d-inline-block" onClick={handleDownHandle3}>
              <h6 className="mb-0 d-inline-block text-center w-100">
                <button
                  className="btn sidebar-accordian-heading-btn btn-inline-block text-center"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="true"
                  aria-controls="collapseThree"
                >
                  <div className="d-inline-block">
                    <img
                      src={isOpen3 ? ArrowDown : ArrowUp}
                      className="arrow-sidebar"
                      alt="ArrowUp"
                    ></img>
                  </div>
                  Admin Apps
                </button>
              </h6>
            </div>
            <hr className="sidebar-hr"></hr>
          </div>
          <div
            id="collapseThree"
            className="collapse show"
            aria-labelledby="headingThree"
          >
            <div
              className="card-body background-color-card-header sidebar-accordian-heading"
              onDragOver={(e) => {
                handleOnDragOver(e);
              }}
              onDrop={(e) => {
                handleOnDrop(e);
              }}
            >
              {apps.map((app) => (
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(<Tooltip id="button-tooltip-2" className='custom-tooltip' >{app.name}</Tooltip>)}
                >
                  <img
                    src={app.appIcon}
                    alt={app.name}
                    width="50px"
                    draggable
                    className='m-1'
                    onDragStart={(e) => {
                      handleDragStart(e, 'app', app.name);
                    }}
                    onClick={() => {
                      history.push(app.route);
                      toggle();
                    }}
                  />
                </OverlayTrigger>

              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
