import React, { useEffect, useState } from "react";

import { getCountriesData } from "../../../services/course-service";
import loader from "../../../assets/loader.gif";

function CourseRight ({ setCurrentCourse, setSelectedSubjects, courseData, showLoader }) {
  const [activeRow, setActiveRow] = useState();
  const handleDownCourse = (course, subjects) => {
    setCurrentCourse(course);
    setSelectedSubjects(subjects);
  };

  useEffect(()=>{
    
    setActiveRow('');
    handleDownCourse('', '')

  }, [courseData])   

  return (
    <div className=" px-5 pt-4 wrapper overflow-auto">
      {showLoader ? (
        <div className="loader">
          <img src={loader} alt="loader" className="loader-image"  />
        </div>
      ) : (
        <table className="table table-borderless text-center">
          <thead>
            <tr className="border-th">
              <th scope="col">Course Name</th>
              <th scope="col">Subjects</th>

              
            </tr>

            <div></div>
          </thead>
          <tbody>
            {courseData.map((row, index) => (
              <tr className={`cursor-pointer  ${ activeRow=== index ? 'bg-blue' : null}`} onClick={() => {
                    handleDownCourse(row.courseName, row.subjects);
                    setActiveRow(index)
                  }}>
                <td
                  className="Country-cursor"
                  
                >
                  {row.courseName}
                </td>
                <td>{row.subjects}</td>

              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default CourseRight;
