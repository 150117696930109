import React from "react";
import "./Style.css"

function Hr(){

    return(

        <div className="Hr-line"></div>

    );
}

export default Hr;