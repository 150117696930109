import React from "react";
import Footer from "../Components/Footer/Footer";



function FranchiseeEnquiry(){


return(
<div>
<div>
<iframe src="https://docs.google.com/forms/d/e/1FAIpQLScxbuzCv8TXfLXItIhscitbqvKdJyJuDyGRqD4Mdj5p6LsVDA/viewform?embedded=true" width="100%" height="1157" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe></div>
<Footer/>
</div>
);



}

export default FranchiseeEnquiry;