import React, { useState, useEffect } from "react";
import StudentLeft from "../StudentLeft/index.js";
import StudentRight from "../StudentRight/StudentRight.js";
import {Wrapper} from './WrapperStyle';
import { getStudentData } from '../../../services/student-service';
import Container from '../../Container';

const WHITE_COLOR = '#F4F4F4';
const BLACK_COLOR = '#343A40';

function StudentMain() {

  const [selectedData, setSelectedData] = useState({});
  const [studentData, setStudentData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    fetchAppData()
  }, []);

  const fetchAppData = async () => {
    setShowLoader(true);
    const res = await getStudentData('temp');

    setShowLoader(false);
    setStudentData(res.data);

  }


  return (
    <Wrapper >

      <Container background={WHITE_COLOR} width='28%' height='76vh' >

        <StudentLeft selectedData={selectedData}   refetch={fetchAppData} />
      </Container>

      <Container background={BLACK_COLOR} width='70%' height='76vh'>


        <StudentRight
          setSelectedData={setSelectedData}
          studentData={studentData}
          showLoader={showLoader}
        />
      </Container>
    </Wrapper>
  );
}

export default StudentMain;
