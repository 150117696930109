import React from 'react';
import { Text } from './style';

function Label({ text, color, fontSize, clickHandler, bold }) {
    return (
        <div onClick={clickHandler}>
            <Text color={color} fontSize={fontSize} isClickable={clickHandler} bold={bold} >
                {text}
            </Text>
        </div>
    )
}
    
export default Label;