import React from "react";
import AboutUsComp from "../Components/AboutUsComp/AboutUsComp";
import Footer from "../Components/Footer/Footer";
import HomepageSlider from "../Components/HomepageSlider/HomepageSlider";
import QuickLinksSlider from "../Components/QuickLinksSlider/QuickLinksSlider";
import VisionMissionComp from "../Components/VisionMissionComp/VisionMissionComp";
import Welcome_Contact from "../Components/Welcome_Contact/Welcome_Contact";





function Homepage(){


return(

<div>
   <HomepageSlider />
   <Welcome_Contact />
   <QuickLinksSlider />
  <AboutUsComp />
   <VisionMissionComp/>
   <Footer/>

</div>

);



}

export default Homepage;