import React from "react";
import "./style.css";
import Hr from "../Helper/Hr/Hr"

function Course({data}) {
  return (
    <div>
      {/*Course Header */}

      <div className="row text-center mt-3">
        <div className="col-4 Course_header">
  <p>Course Code : {data.courseCode}</p>
        </div>
        <div className="col-4 Course_header">
  <p>Eligibility : {data.eligibility}</p>
        </div>
        <div className="col-4 Course_header">
          <p>Duration : {data.duration}</p>
        </div>
      </div>

      {/*Course Details */}

      <h3 className="course_details_heading text-center mt-4 font-weight-bold">Course Details</h3>
      <Hr />
      {data.semesters.map(semester=>(
          <div>
          <h4 className="semester_heading my-3 mx-2 font-weight-bold">{semester.id}</h4>
         {semester.subjects.map(subject=>(
 <li>{subject}</li>

          ))}
          </div>
      ))
    
      }
     
      <p className="font-weight-bold mt-3">Note : Registration & Exam Fees will be charged extra. | Bag and Books will be provided free of cost.</p>
    
    </div>
  );
}

export default Course;
