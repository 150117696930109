import React from "react";
import "./style.css";
import photo from "../../assets/certificate/photo.jpg";
import certified from "../../assets/certificate/certified.png";
import verified from "../../assets/certificate/verified.jpg";
import config from '../../config';

function Certificate(props) {
  return (
    <div>


      <div className="row  margin-table">
    {/* student photo*! */}

       <div className="col-lg-3 col-md-12 d-flex justify-content-center align-self-center">
         <img className="studentinfo-img" src={`${config.BASE_URL}/images/${props.photo}`} alt=""></img>
       </div>

      {/*  /!*table *! */}

        <div className="col-lg-6 col-md-12 table-responsive my-5">
          <table class="table table-bordered text-dark">
            <tbody className="align-middle">

            <tr className="text-center table-blue">
                <td
                  colspan="4"
                  className="align-middle font-weight-bolder marksheet-label-size"
                >
                  Certificate
                </td>
              </tr>

              <tr >
                <td className="align-middle font-weight-bold column-width">
                  Center Name
                </td>
                <td className="align-middle column-width">Future IT Academy, Chandpur</td>
                <td className="align-middle font-weight-bold column-width">
                   Enrollment No.
                </td>
                <td className="align-middle column-width">{props.roll_no}</td>
              </tr>

              <tr >
                <td className="align-middle font-weight-bold column-width">
                  Student's Name
                </td>
                <td className="align-middle column-width">{props.name}</td>
                <td className="align-middle font-weight-bold column-width">
                  Father's Name
                </td>
                <td className="align-middle column-width">{props.father_name}</td>
              </tr>

              <tr >
                <td className="align-middle font-weight-bold column-width">
                  Grade
                </td>
                <td className="align-middle column-width">A</td>
                <td className="align-middle font-weight-bold column-width">
  Course Name
                </td>
                <td className="align-middle column-width">{props.course_name && props.course_name.courseName}</td>
              </tr>




            </tbody>
          </table>
        </div>

        {/*certified photo */}

        <div className="col-lg-3 col-md-12 d-flex justify-content-center align-self-center">
          <img className="certified-img" src={verified} alt="certified"></img>
        </div>
      </div>


    </div>
  );
}

export default Certificate;
