import React, { useState } from "react";
import "./style.css";
import Sidebar from "../Portal/Sidebar/Sidebar";
import SliderRight from "../../assets/SliderRight.svg";
import SliderLeft from "../../assets/SliderLeft.svg";


function SliderPanel() {
 const [isOpen, setIsOpen] = useState(false);

 const handleRightHandle = () => {
     setIsOpen(!isOpen);
 };

  return (
    <div>
      <div className="slider-pannel" style={isOpen ? {width: '20%', padding: '20px'} : {width: '0'}}>
        {isOpen ?
            <>
            <Sidebar toggle={handleRightHandle} />
            </>
            : null}
        <div className='right-handle-wrapper' onClick={handleRightHandle}>
          <div
            style={{
              position: "relative",
            }}
          >
            <div>
              <img className="arrow-right" src={isOpen ? SliderLeft : SliderRight} alt="arrow-right" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SliderPanel;
