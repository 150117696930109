import React from "react";
import Footer from "../Footer/Footer";
import Hr from "../Helper/Hr/Hr";
import HomepageSlider from "../HomepageSlider/HomepageSlider";
import "./style.css";


function HowtogetAffiliation() {
    return (
        <div>

            
            <div className="m-5 text-howtoget ">
            <div className="d-flex justify-content-end">
                <a href="/franchiseeenquiry" className="btn btn-homepage rounded-pill px-2 fw btn-franchisee-form fw-bold" type="submit">Franchisee Enquiry Form</a>
                
              </div>

                <h2>How to get Affiliation / Franchisee?</h2>
                
                <Hr />

                
                <h4>How to take Membership</h4>
                <h5>On receiving this offer, a prospective study centre should.</h5>
                <ul>
                    <li>Apply with complete details of self, premises, available hardware, plan for business development in the formate of the data sheet attached at the end of this offer.</li>
                    <li>Receipt of the application and data sheet at the controlling office of FIA would be followed by personal interview with the Director FIA and a visit by a team of FIA professional at the prospective centre, if needed.</li>
                </ul>

                <h4>Eligibility Criteria for FIA Study Centre</h4>
                <p>The FIA Study Centre should satisfy the following criteria.</p>
                <ul>
                    <li>      Should be in business (IT related or otherwise) or should be a professional with the required entrepreneurial acumen. </li>
                    <li>
                        Should be willing to invest required amount of money in setting up a centre with proper infrastructure and main power.
                                         </li>
                    <li> Should have commitment for providing quality training to students.</li>
                    <li> Should be willing to be a part of the FIA team in accepting the terms and condition and procedures as laid down by FIA. </li>
                </ul>


                <h4>Infrastructure Requirement</h4>


                <table className="table-format">
                    <thead className="table-header">
                        <tr>
                            <th className="table-format pt-2 text-center"><h4>Assets</h4></th>
                            <th className="table-format pt-2 text-center"><h4>Description</h4></th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr >
                            <td className="table-format p-2"> Administrator/Manager </td>
                            <td className="table-format p-2"> Knowledge of Computer Software Training, Experience, having Good Management Skills. </td>

                        </tr>
                        <tr >
                            <td className="table-format p-2"> Faculty </td>
                            <td className="table-format p-2"> Thorough knowledge of subject, disciplined, Student’s problem solving capacity. </td>

                        </tr>
                        <tr>
                            <td className="table-format p-2"> Counsellor/Receptionist </td>
                            <td className="table-format p-2"> Pleasent personality with good communication skills, knowledge of computer courses, disciplined. </td>

                        </tr>
                        <tr>
                            <td className="table-format p-2"> Marketing Executive </td>
                            <td className="table-format p-2"> Experienced Hardworking, knowledge of computer courses, good communication skills. </td>

                        </tr>
                        <tr>
                            <td className="table-format p-2"> Office Boys </td>
                            <td className="table-format p-2"> Disciplined &amp; Experience.  </td>

                        </tr>

                    </tbody>
                </table>

                <h4 className="mt-3 mb-2">Profitability of Operations</h4>
                <p>The granting of study centre status would be possible after careful study of the market potential, number of students graduation form the relevant streams etc.The specifics in terms of operational economics will be discussed in person with each of the study centres.The study centres are required to make a study of the Engineering Colleges, Polytechnics and science college and other institutes of the area by way to the number of students passing out each year.</p>

                <h4>Expectation From the Affiliated Study Centre</h4>
                <p>The study centre should be able to have a centre at a prime location of the city/town. The centre would have separate class rooms, Computer Lab and Reception / office room. The centre would have proper ventilation and proper lighting . The block and sub block level centres may not have the above infrastructure but should have generally clean surroundings.</p>

                <h4>By way of Equipment and Facilities</h4>
                <p>Depending on the potential of the area and under consultation with FIA, the study centre should have or should be in a position to acquire the number of computers, printers, equipment, tools and consumable and the proper software that would be required to effectively convey the course to the students. The study centre should have a well equipped library, housing books as prescribed by the FIA central coordinating office. Further the study centre shall subscribe to periodicals as suggested and students should have easy access to both books and periodicals.</p>

                <h4>In Operations</h4>
                <h5>Each study centre would be provided with a detailed operational manual which provide guidelines to various aspects like.</h5>
                <ul >
                    <li>  Internal systems and procedures  </li>
                    <li>  External Interface </li>
                    <li>  Co-ordinations  </li>
                    <li>  Purchase of classes and practical  </li>
                    <li>  Examination procedure </li>
                    <li> Certificate issue procedures </li>

                </ul>
                <p>The study centre should follow the steps outlined in the manual would be the key essence to the agreement with the study centres.</p>

                <h4>Note:</h4>
                <ul>
                    <li>Kindly fill the form if you are interested for franchisee</li>
                    <li>Feel free to contact or mail us if you have any doubt.</li>
                </ul>

            </div>

           

        </div>
    );
}

export default HowtogetAffiliation;
